import { getRepositoryEndpoint, createClient, Client } from "@prismicio/client";
import { PrismicDocument } from "@prismicio/types";

function newPrismicClient(): Client {
    const endpoint = getRepositoryEndpoint("courtsite-konsol");
    return createClient(endpoint);
}

export async function getKonsolFooterDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("footer", {});
}

export async function getKonsolHomeNewDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("home", {});
}

export async function getKonsolPricingNewDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("pricing", {});
}

export async function getKonsolVenueCarouselDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("venue_carousel", {});
}
