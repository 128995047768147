import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { VariantProps, cva, cx } from "class-variance-authority";

const separatorVariants = cva("shrink-0", {
    variants: {
        color: {
            light: "bg-blue-grey-50",
            dark: "bg-blue-grey-100",
            blueLight: "bg-primary-100",
            blue: "bg-primary",
        },
        orientation: {
            vertical: "h-full w-[1px]",
            horizontal: "h-[1px] w-full",
        },
    },
    defaultVariants: {
        color: "light",
    },
});
type SeparatorVariantProps = React.ComponentPropsWithoutRef<
    typeof SeparatorPrimitive.Root
> &
    VariantProps<typeof separatorVariants>;
const Separator = React.forwardRef<
    React.ElementRef<typeof SeparatorPrimitive.Root>,
    SeparatorVariantProps
>(
    (
        {
            className,
            color,
            orientation = "horizontal",
            decorative = true,
            ...props
        },
        ref,
    ) => (
        <SeparatorPrimitive.Root
            ref={ref}
            decorative={decorative}
            orientation={orientation}
            className={cx(separatorVariants({ color, orientation }), className)}
            {...props}
        />
    ),
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
