import { useEffect, useState } from "react";
import { PrismicDocument } from "@prismicio/types";
import { SliceZone } from "@prismicio/react";
import { getKonsolFooterDoc } from "lib/prismic";
import { components } from "slices";

export const DesktopFooter = (): JSX.Element => {
    const [documentFooter, setDocumentFooter] = useState<PrismicDocument>();

    useEffect(() => {
        if (!documentFooter)
            getPrismicFooter().then((res) => setDocumentFooter(res));
    }, [documentFooter]);

    return (
        <footer className="flex flex-col items-center gap-8 bg-white py-12 lg:gap-16 lg:divide-x-0 lg:divide-y lg:divide-solid lg:divide-blue-grey-50 lg:py-16">
            <SliceZone
                slices={documentFooter?.data.slices}
                components={components}
            />
        </footer>
    );
};

export const getPrismicFooter = async (): Promise<PrismicDocument> =>
    await getKonsolFooterDoc();
