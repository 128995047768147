// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
    default_slice: dynamic(() => import("./DefaultSlice")),
    features_slice: dynamic(() => import("./FeaturesSlice")),
    footer_static_slice: dynamic(() => import("./FooterStaticSlice")),
    growth_slice: dynamic(() => import("./GrowthSlice")),
    help_slice: dynamic(() => import("./HelpSlice")),
    hero_slice: dynamic(() => import("./HeroSlice")),
    infographics_slice: dynamic(() => import("./InfographicsSlice")),
    links_slice: dynamic(() => import("./LinksSlice")),
    price_plans_slice: dynamic(() => import("./PricePlansSlice")),
    pricing_header_slice: dynamic(() => import("./PricingHeaderSlice")),
    pricing_plan_features_slice: dynamic(
        () => import("./PricingPlanFeaturesSlice"),
    ),
    streamline_slice: dynamic(() => import("./StreamlineSlice")),
    testimonials_slice: dynamic(() => import("./TestimonialsSlice")),
    venue_carousel_slice: dynamic(() => import("./VenueCarouselSlice")),
};
